import * as api from '../api';
import * as actionType from '../constants/actionTypes';

// Action creator

export const clearError = () => async (dispatch) => {
    dispatch({type:actionType.AUTH_ERROR_REMOVE});
}

export const contactUs = (formData,history) => async (dispatch) => {
    const {data} = await api.contactUs(formData);
    history('/thankyou');
}

export const getPageWidgets = (page) => async (dispatch) => {
    try {
        dispatch({type:actionType.START_WIDGETS_LOADING});
        const {data} = await api.pageWidget(page);
        
        if(page =='vapes'){
            console.log(data,"Page Vapes")
            dispatch({type:actionType.FETCH_PAGE_VAPES, payload:data});
        }else{
            dispatch({type:actionType.FETCH_PAGE_DRINKS, payload:data});
        }

        
        dispatch({type:actionType.END_WIDGETS_LOADING});
    } catch (error) {
        console.log(error.message);
    }  
}
