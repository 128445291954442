import React, { useEffect } from 'react'
import customdrink from '../Images/drink_bg_img.png';
import customcig from '../Images/cigrate_section_bg.png';
import { SERVER_URL } from '../constants/actionTypes';
import { CategoryBox } from './Home/CategoryBox';

function TopSelling({title,page,drinksBanner,cigarettesBanner,categories}) {
  //alert(page);
   useEffect(()=>{
    // console.log('topselling loaded');
    // console.log(categories);
   },[categories]);
  return (
    <section className='top_selling_section'>
        <div className='container mx-auto'>
            
            <div className='section_title text-center mb-2'>
                <h className="font-primary text-3xl text-black">{title}</h>
            </div>

            <div className='cats_col_block mb-5'>
            { <CategoryBox page={page} categories={categories} /> }
              
            </div>   

        </div>
    </section>
  )
}

export default TopSelling