import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { SERVER_URL } from '../../constants/actionTypes';
import { Link } from 'react-router-dom';

const Box = (category) => {
    
    return(
        <div className='cat_single_block '>
        <div className='cat_img_block'> 
        <Link to={(category.categories.title == 'VAPE') ? 'vapes' : (category.categories.title == 'DRINKS') ? 'drinks' :`/category/${category.categories._id}`}>
            <img 
                src={category.categories.banner 
                    ? SERVER_URL + category.categories.banner 
                    : require('../../Images/best_col_img1.png')} 
                alt={category.categories.title || "Default Image"}
                className='w-full md:max-h-[217px] max-h-full' 
            />
        </Link>
        </div>
        <div className='cat_info bg-mybluecolor p-3 duration-300
        group-hover:bg-myorangeolor'>
            <Link className='flex justify-between' to={(category.categories.title == 'VAPE') ? 'vapes' : (category.categories.title == 'DRINKS') ? 'drinks' :`/category/${category.categories._id}`}>
            <h3 className='text-xl font-primary text-white'>{category.categories.title}</h3>
            <span className='text-white'><FontAwesomeIcon icon={faArrowRight} /></span>
            </Link>
        </div>

    </div>
    );
}


export const CategoryBox = ({categories,page='home'}) => {
   
    
    // console.log(typeof categories);
      
    // const Box = categories.map((category)=>(  ))
        return (
            <>
              {categories.map((category) => {
                // Debugging: log the category and page prop to verify the data
                // console.log("Current page:", page);
                // console.log("Category:", category);
                // console.log("showOnHomePage:", category.showOnHomePage);
          
                // If page is 'home', check showOnHomePage as a string
                if (page === 'home') {
                  if (category.showOnHomePage === '1' || category.showOnHomePage === 1) {
                    // console.log("Rendering category on home:", category.title);
                    return <Box key={category._id} categories={category} />;
                  } else {
                    return null; // skip categories where showOnHomePage is not '1'
                  }
                } 
                // If page is not 'home', render all categories
                else {
                  // console.log("Rendering category on other page:", category.title);
                  return <Box key={category._id} categories={category} />;
                }
              })}
            </>
          );
          
          
}
